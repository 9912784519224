import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Components/Common/Header";
import { Container } from "react-bootstrap";
import CartElement from "../Components/Cart/CartElement";
import CheckoutOrder from "../Components/Cart/CheckoutOrder";
import fetchData from "../Lib/fetchData";
import MainButton from "../Components/Common/MainButton";
import { CartContext } from "../Context/CartContext";
import Loader3Packs from "./Loaders/Loader3Packs";

const Order = ({ id }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState({});
  const [stuff, setStuff] = useState([]);
  const { dispatch } = useContext(CartContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const totalQuantity = useMemo(() => {
    let total = 0;
    stuff.forEach((v) => (total += v.quantity));
    return total;
  }, [stuff]);

  useEffect(() => {
    const getOrder = async () => {
      const ordr = await fetchData(
        `/order/${searchParams.get("id")}`,
        "GET"
      ).then((res) => res.json());
      const promises = [];
      for (const stuff of ordr.orderedStuff) {
        promises.push(
          fetchData(`/stuff?id=${stuff._id}`, "GET").then((res) => res.json())
        );
      }
      await Promise.allSettled(promises).then((stuffList) => {
        const stuffL = new Set();
        for (const oneStuff of stuffList) {
          stuffL.add({
            ...oneStuff.value,
            quantity: ordr.orderedStuff.find(
              (o) => o._id === oneStuff.value._id
            ).quantity,
          });
        }
        setStuff([...stuffL]);
        setLoading(false);
      });
      setOrder(ordr);
    };

    getOrder();
  }, []);

  if (loading) {
    return <Loader3Packs />;
  }

  return (
    <>
      <Container className="w-100 px-0">
        <Container fluid>
        <Header
          title={`Заказ #${searchParams
            .get("id")
            .slice(searchParams.get("id").length - 6)}`}
        />
        </Container>
        {Object.keys(order).length !== 0 && stuff.length !== 0 && (
          <>
          <Container fluid>
            <Container fluid className="cart-elements">
              {stuff.map((v) => (
                <CartElement
                  stuff={v}
                  key={v._id}
                  isFromCart={false}
                  totalQuantity={totalQuantity}
                />
              ))}
            </Container>
          </Container>
            <div className="my-4 order-user-info py-2">
              <p className="mb-0 px-3">
                {order.address?.address && (
                  <>
                    Адрес доставки:
                    <span>{order.address?.address}</span>
                  </>
                )}
              </p>
              <p className="mb-0 px-3">
                {order.phoneNumber && (
                  <>
                    Тел:
                    <span>{order.phoneNumber}</span>
                  </>
                )}
              </p>
              <p className="mb-0 px-3">
                {order.comment && (
                  <>
                    Комментарий:
                    <span>{order.comment}</span>
                  </>
                )}
              </p>
              <p className="mb-0 px-3">
                {order.address?.fullName && (
                  <>
                    Ф.И.О:
                    <span>{order.address?.fullName}</span>
                  </>
                )}
              </p>
              <p className="mb-0 px-3">
                {order.address?.postcode && (
                  <>
                    Индекс:
                    <span>{order.address?.postcode}</span>
                  </>
                )}
              </p>
              <p className="mb-0 px-3">
                {order.pickUpLocation && (
                  <>
                    Точка самовывоза:
                    <span>{order.pickUpLocation}</span>
                  </>
                )}
              </p>
            </div>
          <Container fluid>
            <CheckoutOrder price={order.price} promocodeUsed={order.promocodeUsed} />
            <div
              className="mt-4"
              onClick={() => {
                dispatch({ type: "replace", payload: stuff });
                navigate(
                  `/cart?deliveryType=${order.deliveryType}&paymentType=${
                    order.paymentType
                  }&phoneNumber=${order.phoneNumber}&address=${
                    order.address?.address || ""
                  }&comment=${order.comment || ""}&postcode=${
                    order.address?.postcode || ""
                  }&fullName=${order.address?.fullName || ""}&isInsideMKAD=${
                    order.address?.isInsideMKAD || ""
                  }&pickupLocation=${order.pickUpLocation || ""}&${order.deliveryType !== 'pick_up' ? `geo=${order.address?.addressGeo || ''}` : ""}`
                );
              }}
            >
              <MainButton
                bgColor="#ff9400"
                text="Повторить заказ"
                width="100%"
                color="white"
              />
            </div>
          </Container>
          </>
        )}
      </Container>
    </>
  );
};

export default Order;
