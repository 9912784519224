import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import CartElement from "../../Components/Cart/CartElement";
import { CartContext } from "../../Context/CartContext";
import Checkout from "../../Components/Cart/Checkout";
import CheckoutButton from "../../Components/Cart/CheckoutButton";
import PostFields from "../../Components/Cart/PostFields";
import PickupFields from "../../Components/Cart/PickupFields";
import DeliveryFields from "../../Components/Cart/DeliveryFields";
import Header from "../../Components/Common/Header";
import "./cart.css";
import fetchData from "../../Lib/fetchData";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-dadata/dist/react-dadata.css";
import usdtQR from "../../images/imgs/USDT_QR.png";
import CountDown from "../../Components/Cart/CountDown";
import Loader3Packs from "../Loaders/Loader3Packs";
import sadMrPackImg from "../../images/imgs/sad-mr-pack.png";
import AddressSelector from "../../Components/Cart/AddressSelector";
const Cart = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOutsideMkad, setIsOutsideMkad] = useState(true);
  const [deliveryType, setDeliveryType] = useState(
    searchParams.get("deliveryType") || ""
  );
  const [payment, setPayment] = useState(searchParams.get("paymentType") || "");
  const [isInsideMKAD, setIsInsideMKAD] = useState(
    searchParams.get("isInsideMKAD") !== "false"
  );
  const { cart } = useContext(CartContext);
  const [address, setAddress] = useState(
    searchParams.get("address") ? searchParams.get("address") : ""
  );
  const [phone, setPhone] = useState(
    searchParams.get("phoneNumber")
      ? "+" + searchParams.get("phoneNumber").trim()
      : "+7"
  );
  const [comment, setComment] = useState(searchParams.get("comment") || "");
  const [pickupLocation, setPickupLocation] = useState(
    searchParams.get("pickupLocation") || ""
  );
  const [arriveTime, setArriveTime] = useState(
    searchParams.get("arriveTime") || ""
  );
  const [fullName, setFullName] = useState(searchParams.get("fullName") || "");
  const [postcode, setPostcode] = useState(searchParams.get("postcode") || "");
  const [isPromo, setIsPromo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [isFreeDelivery, setIsFreeDelivery] = useState(false);
  const [isPromoActivated, setIsPromoActivated] = useState(false);
  const [promoId, setPromoId] = useState(-1);
  const navigate = useNavigate();
  const { dispatch } = useContext(CartContext);
  const [vis, setVis] = useState(false);
  const [option, setOption] = useState();
  const [geo, setGeo] = useState(
      searchParams.get("geo") != null ? searchParams.get('geo').split(' ').map(v => Number(v)) : undefined);

  const totalQuantity = useMemo(() => {
    let total = 0;
    cart.forEach((v) => (total += v.quantity));
    return total;
  }, [cart]);

  let canCheckout = true;
  let errorMsg = "";

  if (totalQuantity === 1 && (cart[0].category === "pods" || cart[0].category === 'devices')) {
    canCheckout = cart[0].priceFromOne >= 850;
    errorMsg = "Минимальная сумма заказа на электронки - 850Р";
  } else {
    canCheckout = totalQuantity >= 2;
    errorMsg = "Минимальный заказ - 2шт";
  }

  if(deliveryType === "delivery" && !isOutsideMkad) {
    canCheckout = false;
    errorMsg = "Адрес далеко от курьеров, выберите способ доставки Почта"
  }
  const errorNotify = (msg) => toast.error(msg);

  const sendData = async () => {
    let res;
    if (
      !/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm.test(
        phone
      )
    )
      return errorNotify("Некорректный номер телефона");
    if (payment === "") return errorNotify("Выберите способ оплаты");
    const newCart = cart.map((v) => ({ _id: v._id, quantity: v.quantity }));
    if (deliveryType === "delivery") {
      if (address.length < 5) return errorNotify("Некорректный адрес");
      setLoading(true);
      res = await fetchData("/order", "POST", {
        orderedStuff: newCart,
        paymentType: payment,
        deliveryType,
        comment,
        phoneNumber: phone,
        address: {
          isInsideMKAD,
          addressGeo: geo.join(' '),
          address,
        },
        promocode: promocode,
      });
    } else if (deliveryType === "pick_up") {
      if (pickupLocation === "") return errorNotify("Выберите местоположение");
      if (arriveTime === "") return errorNotify("Выберите время прибытия");
      setLoading(true);
      res = await fetchData("/order", "POST", {
        orderedStuff: newCart,
        paymentType: payment,
        deliveryType,
        comment,
        phoneNumber: phone,
        pickUpLocation: pickupLocation,
        pickUpTime: arriveTime,
        promocode: promocode,
      });
    } else if (deliveryType === "post") {
      if (isNaN(parseInt(postcode))) return errorNotify("Некорректный индекс");
      if (fullName.split(" ").length !== 3)
        return errorNotify("Введите Ф.И.О полностью");
      if (address.length < 5) return errorNotify("Некорректный адрес");
      setLoading(true);
      res = await fetchData("/order", "POST", {
        orderedStuff: newCart,
        paymentType: payment,
        deliveryType,
        comment,
        address: {
          address,
          isInsideMKAD,
          addressGeo: geo.join(' '),
          fullName,
          postcode: Number(postcode),
        },
        phoneNumber: phone,
        promocode: promocode,
      });
    }
    if (res.status === 201) {
      const order = await res.json();
      localStorage.setItem("cart", "[]");
      dispatch({ type: "clear" });
      navigate(`/order-created?userId=${order.userId}&orderId=${order._id}`);
    } else {
      setLoading(false);
      const error = await res.json();
      errorNotify(`Ошибка при оформлении заказа: ${error.message}`);
    }
  };

  const changeMKAD = (isInside) => {
    setIsInsideMKAD(isInside);
  };

  const changeOutsideMkad = (isOutside) => {
    setIsOutsideMkad(isOutside);
  }
  const updatePromo = (promo) => {
    setPromocode(promo);
  };

  const togglePromo = () => {
    setIsPromo((prevState) => !prevState);
  };

  const changeAddress = (addr) => {
    setAddress(addr);
  };
  const changePhone = (number) => {
    setPhone(number);
  };
  const changeComment = (com) => {
    setComment(com);
  };
  const changePickUp = (location) => {
    setPickupLocation(location);
  };
  const changeArrTime = (arrTime) => {
    setArriveTime(arrTime);
    document.body.style.overflowY = "auto";
  };
  const changeFullName = (fn) => {
    setFullName(fn);
  };
  const changePostcode = (pc) => {
    setPostcode(pc);
  };

  useEffect(() => {
    const checkPromo = async () => {
      const data = await fetchData("/users/promo/" + promocode, "GET").then(
        (res) => res.json()
      );
      setIsFreeDelivery(data.available);
      setIsPromoActivated(data.isActivated);
      setPromoId(data.promoCode);
    };
    if (promocode !== "") checkPromo();
  }, [promocode]);

  if (loading) return <Loader3Packs />;

  if (cart.length === 0) {
    return (
      <Container fluid className="cart-main-div h-100">
        <Header title="Корзина" />
        <div
          className="text-center m-auto my-4 h-full d-flex justify-content-center flex-column"
          style={{ height: "70vh" }}
        >
          <div>
            <img src={sadMrPackImg} alt="sad-mr-pack" height={200} />
            <p>Ваша корзина пока пуста</p>
          </div>
        </div>
      </Container>
    );
  }
  
  return (
      <>
        <Container fluid className="cart-main-div h-100">
          <Header title="Корзина"/>
          <Container fluid className="cart-elements">
            {cart.map((v) => (
                <CartElement
                    stuff={v}
                    key={v._id}
                    isFromCart={true}
                    totalQuantity={totalQuantity}
                />
            ))}
          </Container>
          {cart.length !== 0 && (
              <>
                <p className="choose-delivery">Выберите способ доставки</p>
                <div className="delivery-type-div">
                  <button
                      className={
                        deliveryType === "delivery"
                            ? "delivery-type delivery-type-active"
                            : "delivery-type"
                      }
                      onClick={() => setDeliveryType("delivery")}
                  >
                    Доставка
                  </button>
                  <button
                      className={
                        deliveryType === "pick_up"
                            ? "delivery-type delivery-type-active"
                            : "delivery-type"
                      }
                      onClick={() => setDeliveryType("pick_up")}
                  >
                    Самовывоз
                  </button>
                  <button
                      className={
                        deliveryType === "post"
                            ? "delivery-type delivery-type-active"
                            : "delivery-type"
                      }
                      onClick={() => setDeliveryType("post")}
                  >
                    Почта
                  </button>
                </div>
                <p className="choose-delivery">Выберите способ оплаты</p>
                <div className="delivery-type-div">
                  <button
                      className={
                        payment === "usdt"
                            ? "delivery-type delivery-type-active w-50"
                            : "delivery-type w-50"
                      }
                      onClick={() => {
                        setPayment("usdt");
                      }}
                  >
                    USDT
                  </button>
                  {deliveryType !== "post" && (
                      <button
                          className={
                            payment === "cash"
                                ? "delivery-type delivery-type-active w-50"
                                : "delivery-type w-50"
                          }
                          onClick={() => setPayment("cash")}
                      >
                        Наличные
                      </button>
                  )}
                  <button
                      className={
                        payment === "card"
                            ? "delivery-type delivery-type-active w-50"
                            : "delivery-type w-50"
                      }
                      disabled={deliveryType !== 'post'}
                      onClick={() => setPayment("card")}
                  >
                    {deliveryType !== 'post' ?
                        <del>
                          Перевод
                        </del> : 'Перевод'}
                  </button>
                </div>
                {
                    ((deliveryType === 'delivery' || deliveryType === 'post')) && (
                        <div style={{background: 'var(--background-color)', color: 'var(--text-color)'}}>
                          <button style={{
                            width: "100%",
                            padding: "0.75rem",
                            borderRadius: "4px",
                            borderColor: 'var(--input-outline)',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            background: 'var(--background-color)',
                            marginTop: '20px'
                          }} onClick={() => setVis(!vis)}>
                            <div style={{color: 'var(--text-color)'}}>СОХРАНЁННЫЕ АДРЕСА</div>
                            <svg fill="var(--text-color)" style={{ rotate: vis ? '180deg' : '0deg' }} height="15px" width="15px" version="1.1" id="Layer_1"
                                 xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                 viewBox="0 0 330 330" xmlSpace="preserve">
                              <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                    c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                    s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
                            </svg>

                          </button>
                          <div>
                            <AddressSelector vis={vis} option={address} setOption={changeAddress} setVis={setVis} setGeo={setGeo}/>
                          </div>
                        </div>
                    )
                }

                {payment === "usdt" && (
                    <div className="text-center pt-4">
                      <CountDown/>
                      <div className="d-flex mx-auto mt-1" style={{
                        width: 'fit-content'
                      }}>
                        <img src="/tron.svg" width={24} height={24}/>
                        <p style={{
                          color: 'var(--main-text-color)',
                          marginLeft: '6px'
                        }}>USDT TRC-20</p>
                      </div>
                      <img src={usdtQR} alt="TDyCED7Kmwk7sbZ56Tk2kNqYN7jLFg2q1e"/>
                      <p
                          onClick={() =>
                              navigator.clipboard.writeText(
                                  "TDyCED7Kmwk7sbZ56Tk2kNqYN7jLFg2q1e"
                              )
                          }
                      >
                        TDyCED7Kmwk7sbZ56Tk2kNqYN7jLFg2q1e{" "}
                        <i className="bi bi-clipboard"></i>
                      </p>
                    </div>
                )}
                {deliveryType === "delivery" && (
                    <>
                      <DeliveryFields
                          setIsOutsideMkad={changeOutsideMkad}
                          isInsideMKAD={isInsideMKAD}
                          setIsInsideMKAD={changeMKAD}
                          address={address}
                          setAddress={changeAddress}
                          phone={phone}
                          setPhone={changePhone}
                          comment={comment}
                          setComment={changeComment}
                          changePromo={updatePromo}
                          isPromo={isPromo}
                          togglePromo={togglePromo}
                          isPromoActivated={isPromoActivated}
                          isFreeDelivery={isFreeDelivery}
                          promo={promocode}
                          totalQuantity={totalQuantity}
                          setGeol={setGeo}
                          geo={geo}
                      />
                    </>
                )}
                {deliveryType === "pick_up" && (
                    <PickupFields
                        phone={phone}
                        setPhone={changePhone}
                        comment={comment}
                        setComment={changeComment}
                        arriveTime={arriveTime}
                        setArriveTime={changeArrTime}
                        pickupLocation={pickupLocation}
                        setPickupLocation={changePickUp}
                        notifyError={errorNotify}
                        changePromo={updatePromo}
                        isPromo={isPromo}
                        togglePromo={togglePromo}
                        isFreeDelivery={isFreeDelivery}
                        isPromoActivated={isPromoActivated}
                        promo={promocode}
                        totalQuantity={totalQuantity}
                    />
                )}
                {deliveryType === "post" && (
                    <>
                      <PostFields
                          setIsOutsideMkad={changeOutsideMkad}
                          address={address}
                          setAddress={changeAddress}
                          phone={phone}
                          setPhone={changePhone}
                          postcode={postcode}
                          setPostcode={changePostcode}
                          fullname={fullName}
                          comment={comment}
                          setComment={changeComment}
                          setFullname={changeFullName}
                          changePromo={updatePromo}
                          isPromo={isPromo}
                          isPromoActivated={isPromoActivated}
                          togglePromo={togglePromo}
                          isFreeDelivery={isFreeDelivery}
                          promo={promocode}
                          totalQuantity={totalQuantity}
                          setIsInsideMkad={changeMKAD}
                          setGeol={setGeo}
                          geo={geo}
                      />
                    </>
                )}

                {deliveryType !== "" ? (
                    <>
                      <Checkout
                          cart={cart}
                          deliveryType={deliveryType}
                          isInsideMKAD={isInsideMKAD}
                          promocode={promocode}
                          isFreeDelivery={isFreeDelivery}
                          isPromoActivated={isPromoActivated}
                          paymentType={payment}
                          promoId={promoId}
                      />
                      <div className="mt-4 cart-checkout-div">
                        {!canCheckout && (
                            <p className="mt-3 h6 text-danger">{errorMsg}</p>
                        )}
                        <div
                            onClick={() => {
                              if (canCheckout) sendData();
                            }}
                        >
                          <CheckoutButton isEnabled={canCheckout}/>
                        </div>
                      </div>
                    </>
                ) : null}
              </>
          )}
        </Container>
        <ToastContainer
            closeOnClick={true}
            autoClose={2000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            toastClassName="toast-body"
        />
      </>
  );
};

export default Cart;
